<template>
  <div>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">
    <!-- general tab -->
    <b-tab active style="width:100%;">
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('others.general') }}</span>
      </template>

      <my-account-general></my-account-general>
    </b-tab>
    <b-tab style="width:100%;">
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('employee.address') }}</span>
      </template>

      <employee-address-list></employee-address-list>
    </b-tab>

    <!-- change password tab -->
    <b-tab style="width:100%;">
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('others.change_password') }}</span>
      </template>

      <my-account-password></my-account-password>
    </b-tab>
  </b-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {},
    }
  },
}
</script>

